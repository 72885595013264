.footer-container {
    border-top: 0.5px solid rgba(255, 255, 255, 0.3);
    color: #ffffff;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
}

.footer-left, .footer-right {
    /* flex: 1; */
    padding: 20px;
}

.footer-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 1.8rem;
    letter-spacing: 2px;
}

.footer-subtitle {
    font-size: 1rem;
    margin-bottom: 10px;
}
.footer-links-title-light{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    letter-spacing: 1px;
    color:#5b298c;
}
.footer-links-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    letter-spacing: 1px;
    color:#7E66BC;
}

.footer-links {
    list-style: none;
    padding: 0;
}

.footer-links li {
    margin-bottom: 8px;
}

.footer-links-dark a {
    text-decoration: none;
    color: #fff !important;
    font-size: 14px;
    transition: color 0.3s ease;
    display: flex !important;
    font-weight: 400 !important;
    justify-content: space-around !important;
    flex-direction: column;
}


.footer-links-dark a :hover{
    color: #7E66BC !important;
    font-weight: 600 !important;
}
.footer-links a {
    text-decoration: none;
    color: #000000 !important;
    font-size: 14px;
    font-weight: 400 !important;
    transition: color 0.3s ease;
    display: flex !important;
    justify-content: space-around !important;
    flex-direction: column;
}
.footer-links a:hover {
    color: #5b298c !important;
    font-weight: 600 !important;
}

.footer-links a:hover {
    color: #ffcb05;
}

.social-icons {
    display: flex;
    justify-content: flex-start;
    align-items: center; /* Align items vertically */
    gap: 10px; /* Space between icons */
    margin-top: 10px;
  }
  .social-icons-dark {
    display: flex;
    justify-content: flex-start;
    align-items: center; /* Align items vertically */
    gap: 10px; /* Space between icons */
    margin-top: 10px;
  }
  
  .social-icons a {
    font-size: 1.5rem;
    color: black !important;
    transition: transform 0.3s, color 0.3s;
  }
  
  .social-icons-dark a {
    font-size: 1.5rem;
    color: #fff !important;
    transition: transform 0.3s, color 0.3s;
  }
  
  .social-icons a:hover {
    color: #ffcb05;
    transform: scale(1.2);
  }
  

.footer-copyright {
    font-size:14px;
    padding-top: 10px;
    letter-spacing: 1px;
}

.footer-copyright-dark {
    font-size: 14px;
    padding-top: 10px;
    letter-spacing: 1px;
}
.newsemail {
    background-color:black !important;
    color:white !important;
    font-size: 14px;
    font-weight: 500;
    width: fit-content;
    height: 30px;
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    padding: 0 10px; /* Optional: Adds padding for horizontal spacing */
    border-radius: 5px;
}


.downloads {
    background-color:rgb(255, 255, 255) !important;
    color:rgb(0, 0, 0) !important;
    font-size: 14px;
    font-weight: 500;
    width: fit-content;
    height: 30px;
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    padding: 0 10px; /* Optional: Adds padding for horizontal spacing */
    border-radius: 5px;
}

.newsemail-dark {
    background-color:rgb(206, 212, 218) !important;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    width: fit-content;
    height: 30px;
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    padding: 0 10px; /* Optional: Adds padding for horizontal spacing */
    border-radius: 5px;
}

@media (max-width: 768px) {
    .footer-container {
        padding: 10px;
    }
    
    .footer-left, .footer-right {
        /* flex: 1; */
        text-align: center;
        padding: 10px 0;
    }

    .footer-title {
        font-size: 1.3rem;
    }

    .footer-subtitle {
        font-size: 0.9rem;
    }

    .footer-links-title {
        font-size: 1rem;
    }

    .footer-links li {
        margin-bottom: 5px;
    }

    .social-icons {
        justify-content: center;
        font-size: 1.3rem;
        margin-top: 8px;
    }

    .social-icons a {
        margin-right: 8px;
    }

    .footer-copyright {
        font-size: 12px;
    }
    
    .newsemail, .newsemail-dark {
        font-size: 12px;
        height: 25px;
        padding: 0 8px;
    }
}

@media screen and (max-width:995px) {
}

