

  /* Optional: Add a checkmark icon or style */
  .custom-checkbox {
    appearance: none; /* Hide the default checkbox */
    width: 20px !important;
    height: 20px !important;
    background-color: #4B4A4A;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
  }
  
  .custom-checkbox:checked {
    background-color:#5b298c !important;
  }
  
  .custom-checkbox:checked::before {
    content: "✔";
    font-size: 13px;
    color: white;
    position: absolute;
    top: -2px; /* Adjust to vertically center the tick */
    left: 5px; /* Adjust to horizontally center the tick */
  }
  .custom-checkbox1 {
    appearance: none; /* Hide the default checkbox */
    width: 20px !important;
    height: 20px !important;
    background-color: #fff;
    border:1px solid #D1D1D1 ;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
  }
  .font-list{
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  
  .custom-checkbox1:checked {
    background-color:#7E66BC !important;
  }
  
  .custom-checkbox1:checked::before {
    content: "✔";
    font-size: 13px;
    color: white;
    position: absolute;
    top: -2px; /* Adjust to vertically center the tick */
    left: 5px; /* Adjust to horizontally center the tick */
  }
  /* .custom-checkbox:checked {
    content: "✔";
    font-size: 16px;
    color: white;
    background-color: green !important;
  }
  .custom-checkbox {
    content: "✔";
    font-size: 16px;
    color: white;
    background-color: #4B4A4A !important;
  } */
