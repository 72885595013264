.digit-box {
    width: 60px;
    height: 90px;
    background-color: #333; /* Dark background for digital look */
    border-radius: 8px;
    box-shadow: inset 0 4px 10px rgba(0, 0, 0, 0.5); /* Inner shadow for 3D effect */
  }
  
  .digit-text {
    font-family: 'Courier New', Courier, monospace; /* Monospaced font for digital effect */
    font-size: 4rem;
    font-weight: bold;
    color: rgb(255, 253, 253); /* Greenish color for digital style */
  }
  