.img-layout{
    position: relative;
    text-align: center;
    color: white;
    overflow: hidden;
    height: 85vh;   
}
.img-layout1{
    position: relative;
    text-align: center;
    color: white;
    overflow: hidden;
}

  
.text-overlay{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:100%;
    padding:10px;
    /* background-image: linear-gradient(0deg, rgb(0 0 0 / 75%) 0%, rgb(0 0 0 / 52%) 36%, rgb(0 0 0 / 55%) 46%); */
    background-image:linear-gradient(3deg, rgb(0 0 0 / 75%) 0%, #000000ad 36%, #000000b5 46%);
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
  
}

.paratext{
    font-size: 0.875rem;
    margin-bottom: 0px;
}
a{
    text-decoration: none;
}
.btn-card{
    cursor: pointer;
}
.container-value{
    width: 80% !important;
    border: 1px solid #ededed;
    height: 85vh;
    margin-top: 3%;
}
.background-img{
    width: 100%;
    height: 100%;
}
.logo-image{
    width: 130px !important;
    height: 130px !important;

 }
 .logo2{
    width:280px !important;
    height: 60px !important ;
 }

@media only screen and (max-width: 768px) {
/*    
    .img-layout{
        height: 50vh !important;
      
    } */
     .social-icons{
        display: flex;
     flex-wrap: wrap;
     }

     .container-value{
         width:90% !important;
         margin-top: 5%;
     }
     .logo-image{
        width: 60px !important;
        height: 60px !important;

     }
     .logo2{
        height:50px !important ;
     }
  }
