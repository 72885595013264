.toggle-buttons {
  display: flex;
  justify-content: center;
  /* Center buttons horizontally */
  align-items: center;
  /* Center buttons vertically */
  border: 1.5px solid #fff;
  border-radius:5px;
  padding: 3px;
  width:300px ;
}


.toggle-buttons-light {
  display: flex;
  justify-content: center;
  /* Center buttons horizontally */
  align-items: center;
  /* Center buttons vertically */
  border: 1.5px solid #000;
  border-radius: 5px;
  padding: 3px;
  width:300px ;

}

.btn {
  transition: background-color 0.3s ease;
  /* Smooth transition for button background */
}

.notselected {
  background-color: none;
  border-radius: 5px !important;
  width: 150px;
  padding: 5px;
  text-align: center;
  font-size: 14px;
  color: #fff;
}

.notselected-light {
  background-color: none;
  border-radius: 5px !important;
  width: 150px;
  padding: 5px;
  text-align: center;
  font-size: 14px;
  color: #000;
}

.selectedbtn {
  background-color: #7E66BC;
  border-radius: 5px !important;
  width: 150px;
  padding: 5px;
  text-align: center;
  font-size: 14px;
  color: #fff;

}


.selectedbtn-light {
  background-color: #7E66BC;
  border-radius: 5px !important;
  width: 150px;
  padding: 5px;
  text-align: center;
  font-size: 14px;
  color: #fff;

}

.downfont {
  font-size: 10px;
}

.color-card {
  background-color: #fff !important;
}

.color-dark-card {
  background-color: #1B1B1B !important;

}

.header-text {
  font-size: 16px !important;
}

.plan-box {
  color: #fff;
  margin: 10px;;
  background-color: #242424 ;
  border-radius: 5px;
}

.plan-box:hover{
  border:1px solid #7E66BC;
}

.plan-box-light:hover{
  border:1px solid #5b298c
}
.plan-box-light {
  color: #fff;
  background-color: #F4F4F4B2 ;
  border-radius: 5px;
  margin: 10px;
}

.subheadersize {
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  
}

.subheadersize-light {
  font-size: 14px;
  color: #000;
  font-weight: 500;

}

.subscrip-btn {
  background-color: #7E66BC;
  width: fit-content;
  border-radius: 5px;

}

.nonsubscrip-btn {
  border: 1.5px solid #7E66BC;
  border-radius: 5px;
  color: #707070;
}

.nonactiveplan {
  color: #707070;
}

.nonplan-box {
  border: 2px solid #707070;
  color: #fff;
  border-radius: 3px;
  margin: 10px;
}

.mostpopular {
  background-color: #7E66BC;
  color: #fff;
  position: absolute;
  margin-top: -3%;
  left: 40.5%;
  transform: translateX(-50%);
  padding: 3px 15px;
  border-radius: 3px;
  z-index: 1;
}

.font-para {
  color: #000;
}

.font-para-dark {
  color: #fff;
  margin-bottom: 0;
}


.perimum-lists {
  list-style: none; /* Remove default bullets */
  padding: 0;
  margin: 0;
}

.perimum-lists li {
  position: relative;
  padding-left: 30px; /* Space for the tick icon */
  margin-bottom: 20px;
  /* margin-top: 10px; */
  font-size: 10px;
  color: #000 !important;
}

.perimum-lists li::before {
  content: ''; /* No URL here */
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 15px; /* Set desired width */
  height: 15px; /* Set desired height */
  background-image: url('../../../../public/images/checklist.png'); /* Path to the tick image */
  background-size: contain; /* Scale image proportionally */
  background-repeat: no-repeat; /* Prevent repetition */
}
.description-permium {
  font-size: 12px !important;
  color:gray !important;
}

.title-plan{
  font-size: 24px !important;
  font-weight: 600 !important;
}
.monthlydata{
 font-size: 12px !important;
 color:#000;
}
.monthly-dark{
  font-size: 12px !important;
  color:#fff ;
}
.keypoint{
  font-size: 16px !important;
  font-weight: 400 !important;
}
.pricevalue{
  font-weight: 500 !important;
}
.Basicbutton{
  background-color:#5A5A5A !important; 
  color:#fff;
  border-radius: 5px;

}