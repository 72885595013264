/* DisclaimerModal.css */

.disclaimer-modal {
    background-color:transparent; /* Light background for the modal */
    border-radius: 8px; /* Rounded corners for the modal */
}

.modal-title-value {
    font-weight: bold; /* Bold title */
    font-size: 1.5rem; /* Larger title font size */
    color: rgb(126, 102, 188); /* Indigo color for the title */
}

.modal-body-value {
    font-family: 'Arial', sans-serif; /* Change font family */
    color: #333; /* Dark text color for readability */
    line-height: 1.5; /* Improved line spacing */
}

.section-title {
    color: rgb(126, 102, 188); /* Highlighted color for section titles */
    font-weight: bold; /* Bold section titles */
}

.brand-color {
    color: rgb(126, 102, 188); /* Custom brand color */
}

.trademark {
    font-size: 14px; /* Smaller font size for trademark */
    font-weight: 600;
}
.form-check {
    margin-top: 20px; /* Add margin for spacing */
}
.agree-button:disabled {
    background-color: #616161; /* Color for disabled button */
}

.agree-button {
    background-color:rgb(126, 102, 188); /* Color for disabled button */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded button */
}


/* Add this in your CSS file */
.large-modal .modal-dialog {
    max-width: 80%; /* Set width for larger modal */
    width: 80%;     /* Optional, to ensure consistency */
    margin: auto;   /* Center the modal horizontally */
}

.large-modal .modal-body-value {
    padding: 20px; /* Adjust padding for large modal content */
}

.features-list {
    list-style: none; /* Remove default bullets */
    padding: 0;
    margin: 0;
  }
  
  .features-list li {
    position: relative;
    padding-left: 30px; /* Space for the tick icon */
    /* margin-bottom: 10px; */
    font-weight: bold;
    line-height: 20px;
  }
  
  .features-list li::before {
    content: ''; /* No URL here */
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px; /* Set desired width */
    height: 20px; /* Set desired height */
    background-image: url('../../../../public/images/islamic/tick.png'); /* Path to the tick image */
    background-size: contain; /* Scale image proportionally */
    background-repeat: no-repeat; /* Prevent repetition */
  }
  .subscriptionbutton{
     background-color:#5b298c !important;
     color:#fff !important; 
     width: 25% !important;
  }
  
  .subscriptionbutton:hover{
    background-color:#701bc5 !important;
    color: #ffff !important;
  }
  .discription-content{
    font-size: 13px !important;
    color:gray;
  }


  @media (max-width: 768px) {
    .row-item {
      flex-direction: column;
      align-items: flex-start;
    }
    .subscriptionbutton{
  
        width: 100% !important;
     }
     


  }

  .custom-scrollbar::-webkit-scrollbar {
  width: 4px; /* Set scrollbar width */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light grey track */
  border-radius: 5px; /* Rounded corners */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888; /* Dark grey thumb */
  border-radius: 10px; /* Rounded thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Slightly darker on hover */
}