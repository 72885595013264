body {
 margin: 0;
 padding: 0;
 box-sizing: border-box;
 font-family: 'Poppins', sans-serif !important;
 letter-spacing: 0 !important;
  
}

:root{
  --fontpara:16px;
  --titlefont:1.750rem;
  --subtitle:1rem;

}

.font-para{
  font-size: var(--fontpara);
  margin-bottom: 0;
}
.font-title{
  font-size: var(--titlefont);
}
.font-subtitle{
  font-size: var(--subtitle);
}
.pointer{
  cursor: pointer !important;
}


.darkheadcolor{
 color: #7e66bc !important;
}
.lightheadcolor{
  color: #5b298c !important;
}




/* Main container styling */
.fallback-container {
  display: flex;
  flex-direction: column; /* Align logo and text vertically */
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #7e66bc,#5b298c); /* Gradient background */
  color: #fff; /* Text color */
}

/* Animated logo container */
.animated-logo {
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%; /* Circular container */
  animation: pulse 2s infinite; /* Adds pulsing effect */
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.4), 0 0 40px rgba(255, 255, 255, 0.2); /* Glowing effect */
}

/* Logo image styling */
.animated-logo img {
  width: 80%;
  height: 80%;
  object-fit: contain;
  border-radius: 50%; /* Ensures the logo remains circular */
  animation: spin 2s linear infinite; /* Rotating animation */
}

/* Loading text styling */
.loading-text {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1.2px;
  animation: fade 2s infinite;
}

/* Spin animation for the logo */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Pulse animation for the container */
@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.4), 0 0 40px rgba(255, 255, 255, 0.2);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.3);
  }
}

/* Fade animation for the loading text */
@keyframes fade {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
.home-1 .service__item-inner{
  border: 2px solid #5b298c !important;
}
.scrolls {
  color: black;
  text-decoration: none;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.scrolls:hover {
  color: #5b298c; /* Hover color */
}

.active-link {
  color: #5b298c !important;  /* Active link text color */
  border-bottom: 3px solid #5b298c; /* Underline active link */
}
@keyframes smooth-slide {
  0% { transform: translateX(0); }
  50% { transform: translateX(50px); }  /* Move 50px to the right */
  100% { transform: translateX(0); }     /* Return to original position */
}

.smooth-slide-animation {
  animation: smooth-slide 5s infinite ease-in-out;
  margin-top: 80px;
  position: relative;
  width: 100%;
  height: 400px; /* Set a fixed height or relative height here */
  overflow: hidden; /* Prevents overflow of the image outside the container */
}

.banner-image {
  background-image: url('../public/Imagesweb/banner4.webp');
  background-size: contain;  /* Ensures the whole image fits within the div */
  background-position: center;
  background-repeat: no-repeat; /* Prevents tiling */
  width: 100% !important;
  height: 100% !important;  /* Full height of the parent container */
  z-index: 1;
}

.service__item-inner {
  will-change: "transform, opacity";
}






